<template>
  <div class="py-4 container-fluid">
      <div class="row mb-4">
        <div class="col-lg-12 col-md-2">
          <div class="card">   
            <div class="card-body text-center">
              <div class="pb-0 card-header">
                <h3>Guten Tag,</h3>
                <p>herzlich willkommen im TWL-KOM Serviceportal!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4 col-md-6">
          <card
            :img=invoiceIcon
            title="Rechnungen einsehen"
            description="Nehmen Sie Einsicht in Ihre letzten Rechnungen und Einzelverbindungsnachweise."
            buttonText="Zu den Rechnungen"
            buttonLink="/invoice"
            buttonTarget="_self"
          />
        </div>
        <div class="col-lg-4 col-md-6">
          <card
            :img=serviceIcon
            title="Einstellungen prüfen"
            description="Prüfen Sie Ihre Stammdaten, ändern Sie Ihr Kennwort oder sichten Sie die letzten Logins mit Ihrem Zugang."
            buttonText="Zu den Einstellungen"
            buttonLink="/settings"
            buttonTarget="_self"
          />
        </div>
        <div class="col-lg-4 col-md-6">
          <card
            :img=connectIcon
            title="TWL-KOM Tarife"
            description="Mit Highspeed im Homeoffice arbeiten oder Filme und Serien streamen – mit dem TWL-KOM Glasfasernetz kein Problem."
            buttonText="Zu den Tarifen"
            buttonLink="https://twl-kom.de/Privatkunden#TWL-KOM_Privatkunden_Tarife_f%C3%BCr_Internet_und_Telefonie_im_Festnetz_"
            buttonTarget="_blank"
          />
        </div>
      </div>
    </div>
</template>

<script>

// images
import setTooltip from "@/assets/js/tooltip.js";
import Card from "./components/Card.vue";

import connectIcon from  "@/assets/img/twl-kom/icons/blau/TWL-KOM-icon-verbindung-blau.svg"
import serviceIcon from "@/assets/img/twl-kom/icons/blau/TWL-KOM-icon-service-blau.svg"
import invoiceIcon from "@/assets/img/twl-kom/icons/blau/TWL-KOM-icon-management-blau.svg";
//import axios from 'axios';
export default {
  name: "Sales",
  components: {
    Card
  },
  data() {
    return {
        serviceIcon,
        connectIcon,
        invoiceIcon
      }
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);
  },
  methods: {
    
  }
};
</script>
