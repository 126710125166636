<template>
<div id="basic-info" class="card mt-4">
    <div class="card-header pb-3">
      <!--Wird angezeigt wenn locked auf 1 steht-->
      <div v-if="authentications.locked == 1"><material-alert color="danger">Der zum Kundenkonto gehörende Zugang ist aktuell mit folgender Begründung gesperrt: {{ authentications.locked_reason }}<br/>Die Entsperrung kann unter Zugang > Einstellungen aufgehoben werden.</material-alert></div>
      <h5>Stammdaten</h5>
      <p class="text-sm">
        Folgende Stammdaten sind aktuell für den Kunden bekannt. Die Daten werden aus dem K6-System bezogen.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">{{ mandant.id }}</p>
            <p class="mb-0 text-xs">Kundennummer</p>
          </div>
        </div>
      </div>       
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">{{ mandant.firstname }}</p>
            <p class="mb-0 text-xs">Vorname</p>
          </div>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">{{ mandant.lastname }}</p>
            <p class="mb-0 text-xs">Nachname</p>
          </div>
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">{{ mandant.street }}</p>
            <p class="mb-0 text-xs">Straße</p>
          </div>
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">{{ mandant.postcode }}</p>
            <p class="mb-0 text-xs">Postleitzahl</p>
          </div>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">{{ mandant.city }}</p>
            <p class="mb-0 text-xs">Stadt</p>
          </div>
        </div>
      </div>  

    </div>
  </div>

  
  <div id="authentication" class="card mt-4">
    <div class="card-header">
      <h5>Zugang</h5>
      <p class="text-sm">
        Jedem Kundenkonto kann ein Zugangsprofil zugeordnet werden, das aus einer E-Mail-Adresse und einem Kennwort sowie verschiedenen Einstellungen besteht.
      </p>
    </div>

    

    <div class="card-body pt-0">

      <h6>Technische Daten des Zuganges</h6> 

      <!--Wird angezeigt wenn das authentications-Array leer ist-->
      <div v-if="authentications.length == 0"><material-alert color="danger">Das Kontenkonto verfügt aktuell über keinen Zugang zum TWL-KOM Serviceportal. Ein Anlegen über den Adminbereich ist aktuell nicht möglich.</material-alert></div>
      <div v-else>



      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">{{ authentications.uuid }}</p>
            <p class="mb-0 text-xs">Zugangsprofil UUID</p>
          </div>
        </div>
        
        <div class="my-auto ms-3">
          <div class="h-100">          
            <p class="text-sm mb-1" id="authentications.email">{{ authentications.email }}</p>
            <p class="mb-0 text-xs">Zugangsprofil E-Mail-Adresse</p>
          </div>
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">          
            <p class="text-sm mb-1">{{convertTimestampToDate(authentications.time_passwordupdated)}}</p>
            <p class="mb-0 text-xs">Letzte Kennwortänderung</p>
          </div>
        </div>
      </div>
      
    </div>
      <br/>       

      <h6>Einstellungen</h6>
      
      <div class="table-responsive">
        <div class="d-flex align-items-center">
        <table class="table mb-0">
          
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Zugang gesperrt</span>
                  <p class="text-xs">Ist der Zugang gesperrt ist kein Login möglich bzw. die aktuell laufende Session wird unterbrochen. Zugänge werden automatisch nach 10 erfolglosen Loginversuchen gesperrt.</p>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="authentications.locked"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions Email"
                    :checked="authentications.locked"
                    @change="updateSettings()"
                  />
                </div>
              </td>
            </tr>
            
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Kennwortänderung beim nächsten Login erforderlich</span>
                  <p class="text-xs">Wenn aktiviert, muss der Kunde beim nächsten Login bzw. in der aktuell laufenden Session zwingend sein aktuelles Kennwort ändern.</p>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="authentications.need_passwordchange"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions Email"
                    :checked="authentications.need_passwordchange"
                    @change="updateSettings()"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h6>Zugangsdaten</h6>
        <p class="text-sm">
        Hat der Kunde seine Zugangsdaten vergessen, kann ihm über diese Funktion, an die bekannte E-Mail-Adresse ein neues Kennwort zugesendet werden. Nach dem ersten erfolgreichen Login wird der Kunde dazu aufgefordert, ein eigenes Kennwort zu hinterlegen.
        <br/>
        <material-button
        class="mt-6 mb-0"
        variant="filled"
        color="success"
        size="sm"
        @click.once="updateAccess()"
        >Zugang anpassen</material-button>
      </p>
        
        

      </div>
    </div>
  </div>
  <div id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Letzte erfolgreiche Logins</h5>
      <p class="text-sm">
        In dieser Auflistung können ist nachvollziehbar wann und mit welchem Gerät (Agent) die letzten Logins stattgefunden haben.
      </p>
    </div>

    <div class="card-body pt-0">
      <div class="d-flex align-items-center" v-for="login in logins" :key="login.uuid">
        <div class="text-center w-5" >
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">{{login.agent}}</p>
            <p class="mb-0 text-xs">Am {{convertTimestampToDateTime(login.time_created)}} mit der öffentlichen IP {{login.ip}}</p>
          </div>
        </div>
        <material-badge color="success" size="sm" class="my-auto ms-auto me-3"
          >Erfolgreich</material-badge
        >
        
        
      </div>
      <hr class="horizontal dark" />
  </div>
</div>
  
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialBadge from "@/components/MaterialBadge.vue";
import axios from 'axios';

export default {
  name: "SideNavItem",
  components: {
    MaterialButton,
    MaterialSwitch,
    MaterialBadge,
    MaterialAlert
  },
  data() {
    return {
      authentications: [],
      mandant: [],
      logins: [],   
    }
    
  },
  mounted() {
    this.getMandant();
    this.getAuthentication();
    this.getLastLogins();
    
  },
  methods: {
    updateAccess: function() {

      var email = document.getElementById("authentications.email").innerHTML
      var uuid = this.$route.query.uuid;
      var authentication_uuid = this.authentications.uuid
      var self = this  

      this.$swal.fire({
          title: 'Zugang anpassen',
          html:
            'E-Mail-Adresse: ' +
            '<input type="text" class="swal2-input" id="email" name="email" value="' + email + '">' + 
            '<br/><br/>Kennwort zurücksetzen und per Mail versenden: ' + 
            '<input type="checkbox" name="sendpasswordmail" id="sendpasswordmail" value="1"><br/>',
            
          focusConfirm: false,
          preConfirm: () => {

            var formdata = {};

            var form_sendpasswordmail = document.getElementById('sendpasswordmail').checked;
            var form_email = document.getElementById('email').value;



            //Fallunterscheidung Checkbox
            if (form_sendpasswordmail == true) {
              formdata["sendpasswordmail"] = 1;
            } else{
              formdata["sendpasswordmail"] = 0;
            }
            
            formdata["email"] = form_email
            
            const url = self.$path + 'api/admin/authentication/' + uuid + '/' +  authentication_uuid + '/access'
            
            axios.put(url, JSON.stringify(formdata)).then(function(){
              self.$swal({ title: "Erfolg", html: "Die Einstellungen wurden übernommen.", icon: null, position: "top-end", confirmButtonColor: "#A6C516", });
              self.getMandant()
              self.getAuthentication()
            }).catch(function(error){
              self.$swal({ title: "Fehler", html: error, icon: null, position: "top-end", confirmButtonColor: "#A6C516", });
            });;
          }
          })
    },

    updateSettings: function() {
      var self = this   
      var uuid = this.$route.query.uuid;
      var authentication_uuid = this.authentications.uuid

      var locked = Number(document.getElementById("authentications.locked").checked)
      var need_passwordchange = Number(document.getElementById("authentications.need_passwordchange").checked)

      var lockedPreset = self.authentications.locked;
      //wenn der Sperrstatus gesetzt wurde, dann frage nach dem Grund der Sperrung
      if (lockedPreset == 0 && locked == 1) {
        this.$swal.fire({
          title: 'Zugang anpassen',
          html:
            'Sperrgrund: ' +
            '<input type="text" class="swal2-input" id="locked_reason" name="locked_reason">',
            
          focusConfirm: false,
          preConfirm: () => {

            var formdata = {};
            formdata["locked_reason"] = document.getElementById('locked_reason').value;
            formdata["locked"] = 1;
            formdata["need_passwordchange"] = need_passwordchange;

            
            const url = self.$path + 'api/admin/authentication/' + uuid + '/' +  authentication_uuid + '/settings'
            
            axios.put(url, JSON.stringify(formdata)).then(function(){
              self.$swal({ title: "Erfolg", html: "Der Zugang wurde gesperrt.", icon: null, position: "top-end", confirmButtonColor: "#A6C516", });
              self.getAuthentication()
            }).catch(function(error){
              self.$swal({ title: "Fehler", html: error, icon: null, position: "top-end", confirmButtonColor: "#A6C516", });
            });;
          }
          })
      } else {
        var formdata = {};
        formdata["locked"] = locked;
        formdata["need_passwordchange"] = need_passwordchange;
        
        const url = self.$path + 'api/admin/authentication/' + uuid + '/' +  authentication_uuid + '/settings'
        axios.put(url, JSON.stringify(formdata)).then(function(){
          self.getAuthentication()
        }).catch(function(error){
          self.$swal({ title: "Fehler", html: error, icon: null, position: "top-end", confirmButtonColor: "#A6C516", });
        });;
      }
    },
    getAuthentication: function () {
      var uuid = this.$route.query.uuid;

      var postData = {}
      var self = this
      const url = self.$path + 'api/admin/mandant/' + uuid + '/authentication'

      axios.get(url, postData, self.$settings)
    .then(function (response) {
      if (response.data.response[0] != undefined) {
        self.authentications = response.data.response[0]  
      }          
    })
  },
    getMandant: function () {
      var uuid = this.$route.query.uuid;

      var postData = {}
      var self = this
      const url = self.$path + 'api/admin/mandant/' + uuid;

      axios.get(url, postData, self.$settings)
    .then(function (response) {
      self.mandant = response.data.response[0]      
    })
},
getLastLogins: function () {
      var postData = {}
      var self = this
      const url = self.$path + 'api/authentication/v1/log';

      axios.get(url, postData, self.$settings)
    .then(function (response) {
      self.logins = response.data.response
      
    })
    .catch(function (error) {
      if (error.response != undefined) {
        if(error.response.status == 401) {
          self.$router.push('/login')
      }
    }
  })
}


},
};
</script>
