<template>
  <!-- navbar btn-background="bg-gradient-success" / -->
  <div
    class="page-header align-items-start min-vh-100"
    :style="{ backgroundImage: 'url(' + require('@/assets/img/twl-kom/background/twl_shooting_v3_8666_48-1000x667.jpg') + ')' }"
  >
  
  <!-- background-image: url(&quot;https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80&quot;); -->

    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-primary py-3 pe-1"
              >
              
              <div align="center"><img                
              src="/img/logo_twl-kom_rgb_weiss_gruen.39f0ca40.svg"
              class="navbar-brand-img"
              alt=""
              />
              </div>
              </div>
            </div>
            <div class="card-body">
              <div align="center"><h5>Login</h5></div>
              <div v-if="displayLoginError"><material-alert color="danger">{{ displayLoginErrorText }}</material-alert></div>
              <form role="form" class="text-start mt-3" @submit.prevent="login">
                <div class="mb-3">
                  <material-input2           
                    id="email"
                    type="email"
                    label="E-Mail"
                    name="email"
                    
                  />
                </div>
                <div class="mb-3">
                  <material-input2
                    id="password"
                    type="password"
                    label="Kennwort"
                    name="password"
                  />
                </div>
                <!--<material-switch id="rememberMe" name="Remember Me"
                  >Remember me</material-switch>-->
                
                  <p class="text-sm" align="center">
                  Mit dem Betätigen des Anmelden-Buttons akzeptieren Sie den Einsatz technisch notwendiger Cookies.
                  </p>

                

                <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="filled"
                    color="success"
                    :disabled='isDisabled'
                    >Anmelden</material-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © TWL-KOM GmbH
            {{ new Date().getFullYear() }}
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
            <li class="nav-item">
              <a
                href="https://twl-kom.de/kontakt"
                class="nav-link text-white"
                target="_blank"
                >Kontakt</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twl-kom.de/agb"
                class="nav-link text-white"
                target="_blank"
                >AGB</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twl-kom.de/datenschutz"
                class="nav-link text-white"
                target="_blank"
                >Datenschutz</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twl-kom.de/impressum"
                class="nav-link pe-0 text-white"
                target="_blank"
                >Impressum</a
              >
            </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialInput2 from "@/components/MaterialInput2.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import axios from 'axios'

export default {
  data: () => {
    return {
      
      displayLoginError: false,
      displayLoginErrorText: "",
      isDisabled: false
    };
  },
  name: "SigninBasic",
  components: {
    MaterialInput2,
    MaterialButton,
    MaterialAlert,
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    login() {
      this.isDisabled = true;
      var postData = {
        email: document.getElementById("email").value,
        password: document.getElementById("password").value,
      }

      let self = this;
      const url = self.$path + 'signin'
      
      axios.post(url, postData).then(function (response) {

        localStorage.setItem('token', response.data);

        //sichere das Token und schreibe die Default-Config
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ` + token;
        

        const url =  self.$path + 'status'
        axios.get(url, postData).then(function (response) {
          if (response.data.status == "loggedin_code") {
            self.$router.push('/code')
          } else if(response.status == "loggedin_complete") {
            self.$router.push('/')
          } else {
            self.displayLoginError = true
          }
          self.isDisabled = false
        }).catch(function () {})
      })
      .catch(function (error) {
        self.isDisabled = false
        //Zugang gesperrt bzw. Client gesperrt
        if (error.response.status == 403) {          
          self.displayLoginError = true
          //übersetze die (englische Fehlermeldung)
          switch(error.response.data.errormessage) {
            case "Account is locked.":
              self.displayLoginErrorText = "Der Zugang wurde gesperrt";
              break;
              case "Request-Limit reached.":
              self.displayLoginErrorText = "Die Verbindung wurde blockiert";
              break;
            default:
              self.displayLoginErrorText = "Ein Fehler ist aufgetreten"
        }

        //wenn vorhanden, ergänze die Fehlermeldung um den (deutschen Freitext) des Sperrungsgrundes:

        if (error.response.data.response) {
          self.displayLoginErrorText += ": "+error.response.data.response;
        } else {
          self.displayLoginErrorText += "." //ansonsten beende den Satz mit einem Punkt
        }

        } else {
          self.displayLoginError = true
          self.displayLoginErrorText = "Der Login ist fehlgeschlagen. Bitte versuchen Sie es erneut."
        }        
    })
  }
  },
};
</script>
