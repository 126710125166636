<template>

<div v-if="visible == false">
    <div class="py-4 container-fluid">
      <div class="row mb-4">
        <div class="col-lg-12 col-md-2">
          <div class="card">   
            <div class="card-body text-center">
              <div class="pb-0 card-header">
                <h3>Bitte warten...</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto max-height-vh-100 h-100"
    v-if="visible == true"
  >

 

  <nav id="navbarBlur" class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl" v-bind="$attrs"
    data-scroll="true" :class="isAbsolute ? 'mt-4' : 'mt-4'">
    <div class="px-4 py-1 container-fluid">
      <!--<breadcrumbs :color="color" />-->

      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="isRTL ? 'px-0' : 'me-sm-4'">
        <ul class="navbar-nav justify-content-end">
          <li class="px-1 nav-item d-flex align-items-center">
            <router-link :to="{ path: '/logout' }" class="px-0 nav-link font-weight-bold lh-1" :class="color">
              <i class="material-icons">
                logout
              </i>
            </router-link>
          </li>
          <!-- li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a id="iconNavbarSidenav" href="#" class="p-0 nav-link text-body lh-1" @click="toggleSidebar">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li -->
          <!--<li class="px-1 nav-item d-flex align-items-center">
            <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line" :class="color ? 'bg-white' : ''"></i>
                <i class="sidenav-toggler-line" :class="color ? 'bg-white' : ''"></i>
                <i class="sidenav-toggler-line" :class="color ? 'bg-white' : ''"></i>
              </div>
            </a>
          </li>-->

        </ul>
      </div>
    </div>
  </nav>
</div>
</template>
<script>//
//import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapState } from "vuex";
import axios from 'axios';

export default {
  name: "Navbar",
  components: {
    //Breadcrumbs,
  },
  props: {
    minNav: {
      type: Function, 
      default: () => { },
    },
    color: {
      type: String,
      default: "text-body",
    },
  },
  data() {
    return {
      showMenu: false,
      visible: false,      
    };
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute"]),
  },
  beforeMount() {
    this.loggedIn()
  },
  methods: {
    loggedIn: function() {
      var self = this
      var postData = {}
      const url = self.$path + 'api/authentication/v1/'
        axios.get(url, postData, self.$axiossettings)
          .then(function () {
            self.visible = true
        }).catch(function () {
          self.visible = false
        });;
    }, 
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    /*toggleSidebar() {
      this.navbarMinimize();
    },*/
  },
};
</script>
