<template>
  <div class="card">
   
    <div class="card-body text-center">
      
      
      <div class="avatar me-3"><img :src="img" width=48px height=48px alt="kal" class="shadow null border-radius-lg"></div>

      <h5 class="font-weight-normal mt-3">
        <a>{{ title }}</a>
      </h5>

      <p class="mb-0">
        {{ description }}
      </p>
    </div>
    <hr class="dark horizontal my-0" />
    <div class="card-footer d-flex justify-content-center">
      <div v-if="buttonTarget === '_blank'">
        <a :href="buttonLink" :target="buttonTarget">
          <material-button
            class="mt-3 mb-3"
            variant="filled"
            color="success">{{ buttonText }}</material-button></a>
      </div>
      <div v-else>
        <router-link :to="buttonLink">
          <material-button
            class="mt-3 mb-3"
            variant="filled"
            color="success">{{ buttonText }}</material-button>
        </router-link>
      </div> 
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "Card",
  components: {
    MaterialButton
  },
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonLink: {
      type: String,
      required: true
    },
    buttonTarget: {
      type: String,
      required: true
    },
  },
};
</script>
