<script setup>
  const value = defineModel('value', { required: true });
  const props = defineProps(['label','id'])
</script>

<template>
  <div class="input-group input-group-filled">
    <label :for="props.id" class="form-label">{{ label }}</label>
    <input :id="props.id" type="text" v-bind="$attrs" v-model="value" class="form-control"/>
  </div>
</template>
