<template>
  <div class="nav-wrapper position-relative end-0">
   
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "NavPill",
  mounted() {
    setNavPills();
  },
};
</script>
