<template>
  <!-- navbar btn-background="bg-gradient-success" / -->
  <div
    class="page-header align-items-start min-vh-100"
    :style="{ backgroundImage: 'url(' + require('@/assets/img/twl-kom/background/twl_shooting_v3_8666_48-1000x667.jpg') + ')' }"
  >
  
  <!-- background-image: url(&quot;https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80&quot;); -->

    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-primary py-3 pe-1"
              >
              
              <div align="center"><img                
              src="/img/logo_twl-kom_rgb_weiss_gruen.39f0ca40.svg"
              class="navbar-brand-img"
              alt=""
              />
              </div>
              </div>
            </div>
            <div class="card-body">
			<div align="center"><h5>Sicherheitsabfrage</h5></div>

      <div v-if="displayLoginError"><material-alert color="danger">Der eingegebene Code ist nicht gültig.</material-alert></div>
              <p>Geben Sie hier den 6-stelligen Code ein, den Sie in den nächsten Minuten per E-Mail erhalten. Bitte prüfen Sie auch Ihren Spam-Ordner.</p>
              <form role="form" class="text-start mt-3" @submit.prevent="login">
                <div class="mb-3">                  
                  <material-input                  
                    id="code"
                    type="text"
                    label="Code"
                    @input="checkLocalCode()"
                    name="code"/>                    
                </div>                
                <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="filled"
                    color="success"
                    :disabled=isDisabled
                    >Code prüfen</material-button
                  ></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © TWL-KOM GmbH
            {{ new Date().getFullYear() }}
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
            <li class="nav-item">
              <a
                href="https://twl-kom.de/kontakt"
                class="nav-link text-white"
                target="_blank"
                >Kontakt</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twl-kom.de/agb"
                class="nav-link text-white"
                target="_blank"
                >AGB</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twl-kom.de/datenschutz"
                class="nav-link text-white"
                target="_blank"
                >Datenschutz</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twl-kom.de/impressum"
                class="nav-link pe-0 text-white"
                target="_blank"
                >Impressum</a
              >
            </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput2.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations } from "vuex";
import axios from 'axios'

export default {
  data: () => {
    return {
      isDisabled: true
    };
  },
  name: "SigninBasic",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialAlert,
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    checkLocalCode: function() {
      var code = document.getElementById("code").value

      const matches = code.match(/\d{6}/g);

      if (matches != undefined && code.length == 6) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },

    async login() {
      this.isDisabled = true
      
      var postData = {
        code: document.getElementById("code").value,
      }

      let self = this;
      const url = self.$path + 'refresh'
      
      axios.post(url, postData, {})
      .then(function (response) {
        const url = self.$path + 'status'

        localStorage.setItem('token', response.data);

        //sichere das Token und schreibe die Default-Config
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ` + token;


        axios.get(url, postData).then(function (response) {

        
      
      if (response.data.status == "loggedin_code") {
        self.$router.push('/code')
      } else if(response.data.status == "loggedin_complete") {
        self.$router.push('/')
      } else if (response.data.status == "loggedin_pwchange") {
        self.$router.push('/password')
      } else {
        self.$router.push('/login')
      }

      })}).catch(function () {
        self.displayLoginError = true
        self.isDisabled = false
    })
},
  },
};
</script>
