<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${sidebarType}`"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="
            sidebarType === 'bg-white' ||
            (sidebarType === 'bg-transparent' && !isDarkMode)
              ? logoDark
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
        <h1>Serviceportal</h1>
      </router-link>
    </div>

    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />

    <div class="sidenav-footer">
      <hr class="horizontal light mt-0 mb-2" />
      <div align="center"><img
          :src=logoCompany
          class="navbar-brand-img"
          alt=""
        />
    </div>
  </div>
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/twl-kom/icons/weiss/TWL-KOM-icon-partner-weiß.svg";
import logoDark from "@/assets/img/twl-kom/icons/blau/TWL-KOM-icon-partner-blau.svg";
import logoCompany from "@/assets/img/twl-kom/logo/logo_twl-kom_rgb_weiss_gruen.svg";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
      logoCompany,
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode"]),
  },
};
</script>
