<style>
.dataTable-top {
    clear: both;
    margin-top: -20px !important;
    margin-bottom: -15px !important;
    max-width: none !important;
    border-collapse: collapse !important;
    border-spacing: 0;
}
</style>

<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
     
      <div class="d-flex">
        <div class="dropdown d-inline">
          
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Rechnungen</h5>
            <p class="mb-0">
              Hier finden Sie eine übersichtliche Auflistung Ihrer Rechnungen.
            </p>
          </div>
          <div class="table-responsive">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Rechnungsnr.</th>
                  <th>Rechnungsdatum</th>
                  <th>Gesamtbetrag</th>
                  <th>Rechnung</th>
                  <th>Einzelverbindungsnachweis</th>
                </tr>
              </thead>
              <tbody>
                <div v-if="!invoices.length"><p width="100%" text-align="center">Bitte warten...</p></div>
                <tr v-for="invoice in invoices" :key="invoice.id">
                  <td ><p class="text-base">{{invoice.id}}</p></td>
                  <td><p class="text-base">Rechnung vom {{convertTimestampToDate(invoice.time_created)}}</p></td>
                  <td><p class="text-base">{{ parseFloat(invoice.amount).toFixed(2).replace(".",",") }} €</p></td>
                  <td>
                    <router-link :to="'/invoice/reader?id=' + invoice.id + '&uuid=' + invoice.uuid + '&type=invoice'">
                      <material-button                    
                      class="mt-0 mb-0 invoice-btn"
                      variant="filled"
                      color="success"
                      size="sm">Rechnung (PDF)</material-button>
                    </router-link>
                    
                  </td>
                  <td>
                    <div v-if="invoice.evn_exists === 1">
                      <router-link :to="'/invoice/reader?id=' + invoice.id + '&uuid=' + invoice.uuid + '&type=evn'">
                      <material-button
                      class="mt-0 mb-0"
                      variant="filled"
                      color="success"
                      size="sm">EVN (PDF)</material-button>
                    </router-link>
                    </div>
                    <div v-else> - </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- Modal
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Rechnung</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
            <embed width=80% height=100% src=""/>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>  
        </div>
    </div>
</div>-->


</template>

<script>
import { DataTable } from "simple-datatables";
import MaterialButton from "@/components/MaterialButton.vue";
import axios from 'axios';
export default {
  name: "OrderList",
  components: {
    MaterialButton,
  },
  data() {
    return {
        invoiceBaseUrl: self.$path,
        invoices: []
      }
  },
  mounted() {
    this.getInvoices()

   
    
  },
  updated() {
    
    // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search", {
      columns: [{
        select: 0,
        type: "number",
        sort: "desc"}
      ],
      searchable: true,
      fixedHeight: true,
      sortable: true,
      perPage: 15,
      paging: true,
      fixedColumns: true,
      labels: {
        placeholder: "Suchen...",
        searchTitle: "In der Liste suchen",
        pageTitle: "Seite {page}",
        perPage: "",
        noRows: "Keinen Eintrag gefunden",
        info: "Eintrag {start} bis {end} von insgesamt {rows} Einträgen",
        noResults: "Keine Einträge zur Sucheingabe gefunden",
      }});
  }, 
  
  methods: {
    getInvoices: function () {

      var postData = {}
      var self = this
      const url = self.$path + 'api/invoice/v1'

      axios.get(url, postData, self.$settings)
    .then(function (response) {
      self.invoices = response.data.response
    })
    .catch(function (error) {
      if (error.response != undefined) {
        if(error.response.status == 401) {
          self.$router.push('/login')
      }
    }
      
    })




    
  }
  },
};
</script>
