<template>
  <div class="card position-sticky top-1 mt-4">
    <ul class="nav flex-column bg-white border-radius-lg p-3">
      <li class="nav-item pt-2">
        <a class="nav-link text-dark d-flex" data-scroll href="#basic-info">
          <i class="material-icons text-lg me-2">receipt_long</i>
          <span class="text-sm">Stammdaten</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-dark d-flex" data-scroll href="#authentication">
          <i class="material-icons text-lg me-2">lock</i>
          <span class="text-sm">Zugang</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-dark d-flex" data-scroll href="#sessions">
          <i class="material-icons text-lg me-2">settings_applications</i>
          <span class="text-sm">Sessions</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideNav",
};
</script>
