import { createRouter, createWebHistory } from "vue-router";

import Welcome from "../pages/dashboard/Sales.vue";
import Logout from "../pages/login/Logout.vue";
import Login from "../pages/login/Login.vue";
import Code from "../pages/login/Code.vue";
import Password from "../pages/login/Password.vue";
import Invoice from "../pages/invoice/ecommerce/Invoice.vue";
import InvoiceReader from "../pages/invoice/ecommerce/InvoiceReader.vue";
import Settings from "../pages/account/settings/Settings.vue";
//Admin
import Customers from "../pages/admin/ecommerce/Customers.vue";
import CustomerDetails from "../pages/admin/ecommerce/CustomerDetails.vue";
import ClientsBlocked from "../pages/admin/ecommerce/ClientsBlocked.vue";
import InvoiceAdmin from "../pages/admin/ecommerce/Invoice.vue";
import InvoiceAdminReader from "../pages/admin/ecommerce/InvoiceAdminReader.vue";

const routes = [
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/login",
    name: "Signin Basic",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/code",
    name: "Code",
    component: Code,
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/invoice/reader",
    name: "InvoiceReader",
    component: InvoiceReader,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/password",
    name: "Password",
    component: Password,
  },
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  //catchall
  {
    path: "/:pathMatch(.*)*",
    name: "Welcome",
    component: Welcome,
  },


  //Admin
  {
    path: "/admin/customers",
    name: "Customers",
    component: Customers,
  },
  {
    path: "/admin/customer",
    name: "CustomerDetails",
    component: CustomerDetails,
  },
  {
    path: "/admin/invoice",
    name: "InvoiceAdmin",
    component: InvoiceAdmin,
  },
  {
    path: "/admin/clients/blocked",
    name: "ClientsBlocked",
    component: ClientsBlocked,
  },
  {
    path: "/admin/invoice/reader",
    name: "InvoiceReaderAdmin",
    component: InvoiceAdminReader,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
