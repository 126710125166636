

<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Gesperrte Clients</h5> 
            <p class="text-sm mb-0">
              Rufen Clients zu oft Authentifizierungsmechanismen des Kundenportals (/signin, /refresh, /code) auf, wird die verwendete öffentliche IP in Kombination mit dem enstprechenden User-Agent für einige Zeit gesperrt.
            </p>
          </div>
          <div class="table-responsive">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>UUID</th>
                  <th>IP</th>
                  <th>Agent</th>
                  <th>Sperre aktiv bis</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="blockedclient in blockedclients" :key="blockedclient.id">
                  <td>
                    <div class="d-flex align-items-center">
                      <material-checkbox />
                      <p class="text-base font-weight-bold ms-2 mb-0">{{ blockedclient.uuid }}</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-base">{{ blockedclient.ip }}</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-base">{{ blockedclient.agent }}</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-base">{{ convertTimestampToDateTime(blockedclient.valid_until) }}</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <material-button :id=blockedclient.uuid class="mt-0 mb-0 unlock" color="success" variant="filled" size="sm">Entsperren</material-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import axios from 'axios';
export default {
  name: "OrderList",
  components: {
    MaterialButton,
    MaterialCheckbox,
  },
  data() {
    return {
      blockedclients: []
    }
  },
  mounted() {
    this.getBlockedClients()
    
  },
  updated() {
    new DataTable("#datatable-search", {
      searchable: true,
      fixedHeight: true,
      sortable: true,
      perPage: 15,
      paging: true,
      fixedColumns: true,
      columns: [{
        select: 0,
        type: "number",
        sort: "desc"}
      ],
      labels: {
        placeholder: "Suchen...",
        searchTitle: "In der Liste suchen",
        pageTitle: "Seite {page}",
        perPage: "",
        noRows: "Keinen Eintrag gefunden",
        info: "Eintrag {start} bis {end} von insgesamt {rows} Einträgen",
        noResults: "Keine Einträge zur Sucheingabe gefunden",
      }});

      var self = this

      //Event-Handler: Client entsperren
      document.querySelectorAll("#datatable-search tbody tr .unlock").forEach(
        function (e) {
          e.addEventListener("click", function () {
            var uuid = e.id
            self.unblockClient(uuid)

            //lade Liste neu
            self.getBlockedClients()
        })
    }
)
  },
  methods: {
    unblockClient: function (uuid) {
      var postData = {}
      var self = this
      const url = self.$path + 'api/admin/authentication/client/log/unblock/' + uuid

      axios.put(url, postData, self.$settings)
    .then(function (error) {
      self.LangCheckPasswordPolicyText(error.response.data.errormessage)
      this.$swal({ title: "Erfolg", html: "Der Client wurde erfolgreich entsperrt.", icon: null, position: "top-end", confirmButtonColor: "#A6C516", });
    })},

    getBlockedClients: function () {
      var postData = {}
      var self = this
      const url = self.$path + 'api/admin/authentication/client/log/blocked'
      axios.get(url, postData, self.$settings)
    .then(function (response) {
      self.blockedclients = response.data.response
      
    })
    .catch(function (error) {
      if (error.response != undefined) {
        if(error.response.status == 401) {
          self.$router.push('/login')
      }
    }
      
    })
  }
  },
};
</script>
