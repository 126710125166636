import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import MaterialDashboard from "./material-dashboard";
import axios from 'axios'
import Vuex from 'vuex';



const appInstance = createApp(App);

appInstance.config.globalProperties.$path = "https://serviceapi.twl-kom.de/"

appInstance.config.globalProperties.$axiossettings = {
    dataType: 'json',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': ''
    },
    mode: 'no-cors',
    credentials: 'include',
    withCredentials: true,
    withXSRFToken: true
}

//Lade Token aus Speicher, wenn die Seite neugeladen wird
var token = localStorage.getItem('token');
axios.defaults.headers.common['Authorization'] = `Bearer ` + token;

/*axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});*/



appInstance.use(store);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(Vuex);

appInstance.use(MaterialDashboard);
appInstance.mount("#app");

router.beforeEach((to,from,next)=>{

  var postData = {}

  const url = appInstance.config.globalProperties.$path + 'status'

  axios.get(url, postData, appInstance.config.globalProperties.$axiossettings)
    .then(function (response) {

      //Codeabfrage
      if (response.data.status == "loggedin_code") {
        if (to.path == "/code") {
          next()
        } else {
          router.push('/code')
        }
      }

      //Passwortänderung
      else if (response.data.status == "loggedin_pwchange") {
        if (to.path == "/password") {
          next()
        } else {
          router.push('/password')
        }
      //wenn eingeloggt sind prinzipiell alle Aufrufe möglich
      } else if(response.data.status == "loggedin_complete") {
        //wenn eingeloggt, prüfe ob Session verlängert werden kann
        
          const urlRefresh = appInstance.config.globalProperties.$path + 'refresh'
          axios.post(urlRefresh, postData)
          .then(function (response) {
  
            localStorage.setItem('token', response.data);
  
            //sichere das Token und schreibe die Default-Config
            var token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ` + token;
  
            console.log("Session verlängert");
  
          }).catch(function () {});



        if (to.path.match("/admin")) {

          var postData = {}
          const url = appInstance.config.globalProperties.$path + 'api/authentication/v1/'
            axios.get(url, postData, appInstance.config.globalProperties.$axiossettings)
              .then(function (response) {
                var privileges = response.data.response.privileges
                    if(privileges == "admin") {
                      next()
                    } else {
                      router.push('/')
                    }
            }).catch(function () {})
        } else if (to.path.match("/login") || to.path.match("/code") || to.path.match("/password")) {
          router.push('/')
        } else {
          next()
        }
      }
  }).catch(function () {
    if (to.path == "/login") {
      next()
    } else {
      router.push('/login')
    }
      
  });
}, function() {})


appInstance.mixin({

  data: function() {
    return {
      lastRefreshCall: 0
    }
  },
  created: function() {   


       
  },
  methods: {
    convertTimestampToDateTime: function(unixtime) {
      var u = new Date(unixtime*1000);
      return u.toLocaleString('de', {day: '2-digit', month: '2-digit', year: 'numeric', hour: "numeric", minute: "numeric"}) +  " UTC"
    },
    convertTimestampToDate: function(unixtime) {
      var u = new Date(unixtime*1000);
      return u.toLocaleString('de', {day: '2-digit', month: '2-digit', year: 'numeric'})
    },    
    LangCheckPasswordPolicyText: function(text) {
      switch (text) {
        case "Invalid current password.":
          return "Bitte geben Sie Ihr aktuelles Kennwort ein.";
        case "Too short.":
          return "Das neue Kennwort ist zu kurz. Bitte verwenden Sie mindestens 15 Zeichen.";
        case "Invalid old password":
          return "Das aktuelle Kennwort wurde nicht korrekt eingegeben.";
        case "Needs lowercase.":
          return "Es wird mindestens ein Kleinbuchstabe im Kennwort benötigt.";
        case "Needs uppercase.":
          return "Es wird mindestens ein Großbuchstabe im Kennwort benötigt.";
        case "Needs digit.":
          return "Es wird mindestens eine Zahl im Kennwort benötigt.";
        case "Needs special character.":
          return "Es wird mindestens ein Sonderzeichen im Kennwort benötigt.";
        case "New password can't be the old password.":
          return "Das neue Kennwort darf nicht dem alten entsprechen.";
        case "Not matching passwords.":
          return "Die eingegebenen Kennwörter stimmen nicht überein."
        default:
          return "Ein Fehler ist aufgetreten."
        }
    },
    checkPassword: async function(oldPassword, newPassword, newPassword2) {
      var self = this
      
      if (newPassword != "" && newPassword2 == "") {

        var postData = {}
        postData.password = newPassword;
        
        const url = self.$path + 'api/authentication/v1/password/verify';

        //Validiere das Kennwort gegen die API
        return await axios.post(url, postData, self.$settings)
        .then(function () {          
          //wenn fehlerfrei
          if (oldPassword < 8) {
            return "Invalid current password."
          } else {
            return "-"
          }        
        }).catch(function (error) {
          return error.response.data.errormessage;
      })} else if (newPassword != "" && newPassword2 != "") {
        //das zweite Kennwort wird nun eingegeben

        //prüfe ob die beiden Kennwörter übereinstimmen
        if (newPassword == newPassword2 && newPassword != "") {
          return "";
        } else {
          return  "Not matching passwords.";
        }
      } else {
        return "-";
      } 
    },
    updatePassword: async function(password, newPassword) {
      var self = this;

      var postData = {}
      postData.oldPassword = password;
      postData.newPassword = newPassword;
      
      const url = self.$path + 'api/authentication/v1/password';

      return await axios.put(url, postData, self.$settings)
        .then(function () {
          self.$swal({ title: "Erfolg", html: "Das Kennwort wurde erfolgreich geändert.", icon: null, position: "top-end", confirmButtonColor: "#A6C516", });

          return "reset";
      }).catch(function (error) {
        if (error.response) {
          self.$swal({ title: "Fehler", html: self.LangCheckPasswordPolicyText(error.response.data.errormessage), icon: null, position: "top-end", confirmButtonColor: "#A6C516", });
          
          return "";
        }
      })
    },
  },
})



