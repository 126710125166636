<template>

  
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    v-if="isVisible == 1"
  >
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === '' ? 'active' : ''">
        <router-link to="/" class="nav-link">
          <div class="text-center d-flex align-items-center justify-content-center me-2">
            <i class="material-icons-round opacity-10">dashboard</i>
          </div>
          <span class="nav-link-text ms-1">Dashboard</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getRoute() === 'invoice' ? 'active' : ''">
        <router-link to="/invoice" class="nav-link">
          <div class="text-center d-flex align-items-center justify-content-center me-2">
            <i class="material-icons-round opacity-10">task</i>
          </div>
          <span class="nav-link-text ms-1">Rechnungen</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getRoute() === 'settings' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="account"
          nav-text="Mein Konto"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">person</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item
                :to="{ name: 'Settings' }"
                mini-icon="settings"
                text="Einstellungen"
                url="#"
              />
              <sidenav-item
                :to="{ name: 'Logout' }"
                mini-icon="logout"
                text="Logout"
                url="#"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      
      
      
      <div v-if="isAdmin==true">
        <li class="nav-item" :class="getRoute() === 'admin' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="admin"
          nav-text="Admin"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">person</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item
                :to="{ path: '/admin/customers' }"
                mini-icon="group"
                text="Kundenübersicht"
                url="#"
              />
              <sidenav-item
                :to="{ path: '/admin/clients/blocked' }"
                mini-icon="lock"
                text="Gesperrte Clients"
                url="#"
              />
            </ul>
          </template>
          
        </sidenav-collapse>
      </li>
    </div>
      
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import axios from 'axios';
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      isAdmin: false,
      isVisible: false
      
    }    
  },
  beforeMount() {
    this.isAdminCheck()
  },
  methods: {
    isAdminCheck: function() {
      var self = this
      var postData = {}
      const url = self.$path + 'api/authentication/v1/'
        axios.get(url, postData, self.$axiossettings)
          .then(function (response) {
            var privileges = response.data.response.privileges
            

            if(privileges === "admin") {
              self.isAdmin = true
              self.isVisible = true
            } else if(privileges === "user") {
              self.isAdmin = false
              self.isVisible = true
              }else {
              self.isAdmin = false
              self.isVisible = false
            }
        }).catch(function () {
          self.isAdmin = false
          self.isVisible = false
        });;
    },    
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
