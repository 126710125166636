<template>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Stammdaten</h5>
      <p>
        Folgende Stammdaten haben wir aktuell in unseren Systemen zu Ihnen erfasst.
      </p>    
    </div>

    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="mb-1">{{ mandant.id }}</p>
            <p class="mb-0 text-xs">Kundennummer</p>
          </div>
        </div>
      </div>       
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="mb-1">{{ mandant.firstname }}</p>
            <p class="mb-0 text-xs">Vorname</p>
          </div>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="mb-1">{{ mandant.lastname }}</p>
            <p class="mb-0 text-xs">Nachname</p>
          </div>
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="mb-1">{{ mandant.street }}</p>
            <p class="mb-0 text-xs">Straße</p>
          </div>
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="mb-1">{{ mandant.postcode }}</p>
            <p class="mb-0 text-xs">Postleitzahl</p>
          </div>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="mb-1">{{ mandant.city }}</p>
            <p class="mb-0 text-xs">Stadt</p>
          </div>
        </div>
      </div>
      <br/>
      <p>Wenn Sie eine Änderung Ihrer Stammdaten wünschen, kontaktieren Sie uns einfach per Mail an <a href="mailto:customer.care@twl-kom.de">customer.care@twl-kom.de</a></p>
    </div>



  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Kennwort ändern</h5>
    </div>
    <div class="card-body pt-0">
    <h6>Kennwortanforderungen</h6>
      <p>
        Folgende Komplexitätsanforderungen gelten für Passwörter:
      </p>
      
      <ul class="mb-0 float-start">
        <li>
          <p class="mb-0">Mindestens je eine Zahl, Sonderzeichen, Groß- und Kleinbuchstabe</p>
        </li>
        <li>
          <p class="mb-0">Mindestens 15 Zeichen</p>
        </li>
      </ul>
    </div>
    
    <form role="form" class="text-start mt-3" @submit.prevent="changepw">
    <div class="card-body pt-0">
      <div class="mb-3">
        <material-input
          id="oldPassword"
          type="password"
          value=""
          label="Aktuelles Kennwort"
          @input="checkLocalPassword()"
        /> 
      </div>
      <div class="mb-3">
        <material-input
        id="newPassword"
        type="password"
        value=""
        label="Neues Kennwort"
        @input="checkLocalPassword()"/>
      </div>
      <div class="mb-3">
        <material-input
          id="newPassword2"
          type="password"
          value=""
          label="Neues Kennwort bestätigen"
          @input="checkLocalPassword()"/>
      </div>
      <div v-if="errormessageText != ''"><material-alert color="danger">{{ errormessageText }}</material-alert></div>
      
      <material-button
        class="mt-6 mb-0"
        variant="filled"
        color="success"
        size="sm"
        :disabled=!passwordOK
        @click="updateLocalPassword()"
        >Kennwort ändern</material-button
      >

      
    </div>
  </form>
  </div>
  <div id="2fa" class="card mt-4">
    <div class="card-header d-flex">
      <h5 class="mb-0">Zwei-Faktor-Authentifizierung</h5>
      <material-badge color="success" class="ms-auto">Aktiviert</material-badge>      
    </div>
    <div class="card-body pt-0">
      <p>
        Standardmäßig ist die Zwei-Faktor-Authentifizierung aktiv, sodass Sie bei jedem Loginversuch einen Code an Ihre E-Mail-Adresse erhalten. Diese Sicherheitsfunktion kann nicht deaktiviert werden.
      </p>
    </div>
  </div>
  
  <!--<div id="notifications" class="card mt-4">
    <div class="card-header">
      <h5>Benachrichtigungen</h5>
      
    </div>
    <div class="card-body pt-0">
      <p class="text-sm">
        Konfigurieren Sie zu welchen Aktivitäten Sie Benachrichtigungen erhalten möchten.
      </p>
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                Aktivität
              </th>
              <th class="text-center">
                E-Mail
              </th>
              <th class="text-center">
                <p class="mb-0">Push</p>
              </th>
              <th class="text-center">
                <p class="mb-0">SMS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="d-block text-sm"><p class="text-sm">Rechnungen</p></span>
                  <span class="text-xs font-weight-normal"><p class="text-sm">Sie werden benachrichtigt sobald eine neue Rechnung zum Abruf bereitsteht.</p></span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="mentionsEmail"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions Email"
                    checked
                    disabled
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="mentionsPush"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions Push"
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="mentionsSMS"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions SMS"
                  />
                </div>
              </td>
            </tr>            
          </tbody>
        </table>
      </div>
    </div>
  </div>-->
  <div id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Letzte erfolgreiche Logins</h5>
      <p>
        In dieser Auflistung können Sie nachvollziehen wann und mit welchem Gerät (Agent) die letzten Logins mit Ihrem Zugang stattgefunden haben.
      </p>
    </div>

    <div class="card-body pt-0">
      <div class="d-flex align-items-center" v-for="login in logins" :key="login.uuid">
        <div class="text-center w-5" >
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">{{login.agent}}</p>
            <p class="mb-0 text-xs">Am {{convertTimestampToDateTime(login.time_created)}} mit der öffentlichen IP {{login.ip}}</p>
          </div>
        </div>
        <material-badge color="success" size="sm" class="my-auto ms-auto me-3"
          >Erfolgreich</material-badge
        >
        
        
      </div>
      <hr class="horizontal dark" />
  </div>
</div>
    
  
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialBadge from "@/components/MaterialBadge.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialInput from "@/components/MaterialInput2.vue";
import axios from 'axios';

export default {
  name: "SideNavItem",
  components: {
    MaterialButton,
    MaterialBadge,
    MaterialInput,
    MaterialAlert
  },
  data() {
    return {
      mandant: [],
      logins: [],      
      displayLoginError: false,
      errormessageText: "",
      errormessage: false,
      passwordOK: false
    }
    
  },
  mounted() {
    this.getMandant()
    this.getLastLogins()
  },
  methods: {

    updateLocalPassword: async function() {
      var password = document.getElementById("oldPassword").value
      var newPassword = document.getElementById("newPassword").value

      var r = await this.updatePassword(password, newPassword);
      if (r == "reset") {
        this.passwordOK = false;
        document.getElementById("oldPassword").value = "";
        document.getElementById("newPassword").value = "";
        document.getElementById("newPassword2").value = "";
      }
    },
    

    checkLocalPassword: async function() {
      var self = this;

      var oldPassword = document.getElementById("oldPassword").value
      var newPassword = document.getElementById("newPassword").value
      var newPassword2 = document.getElementById("newPassword2").value

      var error = await self.checkPassword(oldPassword, newPassword, newPassword2);

      if (error == "" || error == undefined) {
        self.errormessageText = "";
        self.passwordOK = true;
      } else if (error == "-") {
        self.passwordOK = false;
        self.errormessageText = ""
      } else {
        
        self.errormessageText = self.LangCheckPasswordPolicyText(error);
        self.passwordOK = false;
      }
    },
    getMandant: function () {
      var postData = {}
      var self = this
      const url = self.$path + 'api/mandant/v1';

      axios.get(url, postData, self.$settings)
    .then(function (response) {
      self.mandant = response.data.response[0]
      
    })
},
  getLastLogins: function () {
      var postData = {}
      var self = this
      const url = self.$path + 'api/authentication/v1/log';

      axios.get(url, postData, self.$settings)
    .then(function (response) {
      self.logins = response.data.response
      
    })
},
},
};
   
</script>
