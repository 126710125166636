<style>
.dataTable-top {
    clear: both;
    margin-top: -20px !important;
    margin-bottom: -15px !important;
    max-width: none !important;
    border-collapse: collapse !important;
    border-spacing: 0;
}
</style>

<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
     
      <div class="d-flex">
        <div class="dropdown d-inline">
          
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Rechnung Nr. {{ this.id }} {{ this.titleDec }}</h5>
          </div>

          <router-link :to="'/invoice'">
            <material-button                    
              class="mt-0 mb-0 invoice-btn"
              variant="filled"
              color="success"
              size="sm">Zurück zur Rechnungsübersicht</material-button>
          </router-link>

          <br/>

          <div v-if="displayError"><br/><material-alert color="danger">{{ displayLoginErrorText }}</material-alert></div>            
          <object :data="pdfData" width="100%" height="600px" type="application/pdf;base64">
          </object>
        </div>
      </div>
    </div>
  </div>




</template>

<script>
import axios from 'axios';
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialButton from "@/components/MaterialButton.vue";
export default {
  name: "OrderList",
  components: {
    MaterialAlert,
    MaterialButton
  },
  data() {
    return {
        pdfData: "",
        invoice_uuid: "",
        type: "",
        id: "",
        titleDec: "",
        displayError: false
      }
  },
  mounted() {
    
    this.invoice_uuid = this.$route.query.uuid;
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    this.openDocument();

    if (this.type == "evn") {
      this.titleDec = "(Einzelverbindungsnachweis)"
    }

   
    
  },
  updated() {
  },
  
  methods: {
    openDocument: async function() {
      var url = this.$path + 'api/invoice/v1/' + this.invoice_uuid + '/'+this.type+'_document/raw'

      var self = this
      var postData = {};
      return await axios.get(url, postData)
        .then(function (response) {
          self.pdfData = "data:application/pdf;base64," + encodeURI(response.data)
      }).catch(function () {
        self.displayLoginErrorText = "Das Dokument konnte nicht geladen werden, da ein Fehler aufgetreten ist. Bitte versuchen Sie es erneut."
        self.displayError = true;

      })
    },
  },
};
</script>
