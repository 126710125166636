<script>
import { mapMutations } from "vuex";
import axios from 'axios'

export default {
  data: () => {
    return {
    };
  },
  name: "SigninBasic",
  components: {
  },
  mounted() {
    this.logout();
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    logout() {
      let self = this;
      const url = self.$path + 'logout'
        axios.post(url, {}).then(function () {

          localStorage.removeItem("token");
          axios.defaults.headers.common['Authorization'] = undefined;

          self.$router.push('/login');          
        })
      },
    },
  };
</script>
