<template>
  <!-- navbar btn-background="bg-gradient-success" / -->
  <div
    class="page-header align-items-start min-vh-100"
    :style="{ backgroundImage: 'url(' + require('@/assets/img/twl-kom/background/twl_shooting_v3_8666_48-1000x667.jpg') + ')' }"
  >
  
  <!-- background-image: url(&quot;https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80&quot;); -->

    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-primary py-3 pe-1"
              >
              
              <div align="center"><img                
              src="/img/logo_twl-kom_rgb_weiss_gruen.39f0ca40.svg"
              class="navbar-brand-img"
              alt=""
              />
              </div>
              </div>
            </div>
            <div class="card-body">
              <div align="center"><h5>Kennwortänderung</h5></div>
              <p>Eine Kennwortänderung ist erforderlich. Bitte legen Sie ein neues Kennwort fest.</p>
              <div v-if="errormessageText != ''"><material-alert color="danger">{{ this.errormessageText }}</material-alert></div>
              <form role="form" class="text-start mt-3" @submit.prevent="changepw">
                <div class="mb-3">
                  <material-input2
                    id="oldPassword"
                    type="password"
                    label="Altes Kennwort"
                    value=""
                    name="oldPassword"
                    @input="checkLocalPassword()"
                  />
                </div>
                <div class="mb-3">
                  <material-input2
                    id="newPassword"
                    type="password"
                    label="Neues Kennwort"
                    value=""
                    name="newPassword"
                    @input="checkLocalPassword()"
                  />
                </div>
                <div class="mb-3">
                  <material-input2
                    id="newPassword2"
                    type="password"
                    label="Neues Kennwort (Wiederholung)"
                    value=""
                    name="newPassword2"
                    @input="checkLocalPassword()"
                  />
                </div>
                <!--<material-switch id="rememberMe" name="Remember Me"
                  >Remember me</material-switch>-->
                
                  <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="filled"
                    color="success"
                    :disabled=!passwordOK
                    @click="updateLocalPassword()"
                    >Kennwort ändern</material-button
                  >
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © TWL-KOM GmbH
            {{ new Date().getFullYear() }}
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
            <li class="nav-item">
              <a
                href="https://twl-kom.de/kontakt"
                class="nav-link text-white"
                target="_blank"
                >Kontakt</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twl-kom.de/agb"
                class="nav-link text-white"
                target="_blank"
                >AGB</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twl-kom.de/datenschutz"
                class="nav-link text-white"
                target="_blank"
                >Datenschutz</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twl-kom.de/impressum"
                class="nav-link pe-0 text-white"
                target="_blank"
                >Impressum</a
              >
            </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MaterialInput2 from "@/components/MaterialInput2.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations } from "vuex";

export default {
  data: () => {
    return {
      errormessageText: "",
      passwordOK: false,
    };
  },
  name: "SigninBasic",
  components: {
    MaterialInput2,
    MaterialButton,
    MaterialAlert
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    updateLocalPassword: async function() {
      var password = document.getElementById("oldPassword").value
      var newPassword = document.getElementById("newPassword").value

      var r = await this.updatePassword(password, newPassword);
      if (r == "reset") {
        this.$router.push('/')
      }
    },

    checkLocalPassword: async function() {
      this.isDisabled = true

      var self = this;

      var oldPassword = document.getElementById("oldPassword").value
      var newPassword = document.getElementById("newPassword").value
      var newPassword2 = document.getElementById("newPassword2").value

      var error = await self.checkPassword(oldPassword, newPassword, newPassword2);

      if (error == "" || error == undefined) {
        self.errormessageText = "";
        self.passwordOK = true;
      } else if (error == "-") {
        self.passwordOK = false;
        self.errormessageText = ""
      } else {
        
        self.errormessageText = self.LangCheckPasswordPolicyText(error);
        self.passwordOK = false;
      }
    }
  }
};
</script>
