<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Kundenübersicht</h5>
            <p class="text-sm mb-0">
              Kundendaten werden aus dem K6 importiert. Für jeden Kunden kann aktuell <b>ein</b> Portal-Zugang (E-Mail-Adresse/Kennwort) verwaltet werden.<br/>
              Das Anlegen von Zugängen für neue Kunden übernimmt Team Development.
            </p>
          </div>
          <div class="table-responsive">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Kundennr.</th>
                  <th>Firma</th>
                  <th>Vorname</th>
                  <th>Nachname</th>
                  <th>Straße</th>
                  <th>Postleitzahl</th> 
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                <div v-if="!customers.length"><p width="100%" text-align="center">Bitte warten...</p></div>
                <tr v-for="customer in customers" :key="customer.id">
                  <td>
                    <div class="d-flex align-items-center">
                      <material-checkbox />
                      <p class="text-base font-weight-bold ms-2 mb-0">{{ customer.id }}</p>
                    </div>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-base">{{ customer.company }}</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-base">{{ customer.firstname }}</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-base">{{ customer.lastname }}</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-base">{{ customer.street }}</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-base">{{ customer.postcode }}</span>
                  </td>
                  <!--<td class="text-base font-weight-bold">
                    <div class="d-flex align-items-center">
                      <div v-if="customer.locked==1">
                      <material-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-check" aria-hidden="true"></i>
                      </material-button>
                      
                    </div>
                    <div v-else>
                      <material-button
                        color="danger"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-times" aria-hidden="true"></i>
                      </material-button>
                    </div>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-base">01.01.1970</span>
                  </td>-->
                  
                  <td class="text-xs font-weight-bold">
                    <router-link :to="'/admin/customer?uuid=' + customer.id">
                      <material-button class="mt-0 mb-0" variant="filled" color="success" size="sm">Verwalten</material-button>
                    </router-link>
                    &nbsp;
                    <router-link :to="'/admin/invoice?customer_id=' + customer.id">
                      <material-button class="mt-0 mb-0" variant="filled" color="success" size="sm">Rechnungen</material-button>
                  </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import axios from 'axios';
export default {
  name: "OrderList",
  components: {
    MaterialButton,
    MaterialCheckbox,
  },
  data() {
    return {
      customers: []
    }
  },
  mounted() {
    this.getCustomers()
    
  },
  updated() {
    
    // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search", {
      columns: [{
        select: 0,
        type: "number",
        sort: "desc"}
      ],
      searchable: true,
      fixedHeight: true,
      sortable: true,
      perPage: 15,
      paging: true,
      fixedColumns: true,
      labels: {
        placeholder: "Suchen...",
        searchTitle: "In der Liste suchen",
        pageTitle: "Seite {page}",
        perPage: "",
        noRows: "Keinen Eintrag gefunden",
        info: "Eintrag {start} bis {end} von insgesamt {rows} Einträgen",
        noResults: "Keine Einträge zur Sucheingabe gefunden",
      }});
  },
  methods: {
    hasAuthenticationMethod: function (id) {
      var postData = {}
      var self = this
      const url = self.$path + 'api/admin/mandant/' + id + '/authentication'

      axios.get(url, postData, self.$settings)
    .then(function (response) {
      var authentications = response.data.response
      if (authentications.length == 0) {
        return false
      } else {
        return true
      }      
    })},

    getCustomers: function () {

      var postData = {}
      var self = this
      const url = self.$path + 'api/admin/mandant'

      axios.get(url, postData, self.$settings)
    .then(function (response) {
      self.customers = response.data.response
      
    })
    .catch(function (error) {
      if (error.response != undefined) {
        if(error.response.status == 401) {
          self.$router.push('/login')
      }
    }
      
    })
  }
  },
};
</script>
